import { tags } from '../../projects/constants';

const { en } = tags;

const projectsPageEn = {
  mongabay: {
    title: "Mongabay Graphic Package",
    introduction: "Mongabay is an environmental news portal based in different regions and needed to standardize its communication and make life easier for editors collaborating around the world. To that end, we created new layouts for its video graphics package in order to be versatile and adapt to social midia, without losing the Mongabay identity.",
    paragraph1: "We made an essential graphics package that can be easily used in Adobe Premiere through a simple interface and with some customization options for the editor.",
    paragraph2: "The graphics are automated to adapt to the content. Programming codes control the size of text boxes, number of lines etc, so that the editor has the minimun",
    paragraph3: "We made vertical and horizontal versions so that the information behaved similarly in both formats without needing adaptations by the editorials. It was a great partnership with Mongabay's video coordinator, Lucia Torres, so that the graphic package had the best use internally.",
    credits: {
      title: "Credits",
      info: [
        {
          role: "Coordination and motion design",
          name: "Julia Lima",
        },
        {
          role: "Essential graphics",
          name: "Carla Dutra",
        },
        {
          role: "Programmer ",
          name: "Felipe Fernandes",
        },
        {
          role: "Intern",
          name: "Ana Clara Lury",
        },
      ]
    },
    tags: ['animation', 'environment'],
  },
  plenamata: {
    title: "Plenamata",
    introduction: `Plenamata is a portal with real-time data and news about deforestation in the Amazon. Made possible by Natura together with MapBiomas and InfoAmazonia, it brings together indicators, analyses, in addition to giving visibility to conservation and regeneration initiatives in the Amazon.
    We were very happy to participate a little in this initiative with illustrations, gifs and infographics for the website's glossary.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Art Direction and illustrations",
          name: "Julia Lima",
        },
        {
          role: "Design and animation assistant",
          name: "Carla Dutra",
        },
        {
          role: "Intern",
          name: "Ana Clara Lury",
        },
      ]
    },
    tags: [en.INFOGRAPHIC, en.ILLUSTRATION, en.ENVIRONMENT],
  },
  refarm: {
    title: "Refarm",
    introduction: `Delightful project to talk about beautiful pieces from Farm in two versions, knits and jeans. The brand has been positioning itself for some time on several fronts to reach the #carbonzero mark and the animations list some specific ESG actions, promoting conscious and socially conscious practices :)`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Illustrations and Animation",
          name: "Julia Lima",
        },
        {
          role: "Soundtrack",
          name: '"Adoro" - Flor de Sal',
        },
        {
          role: "Voice over",
          name: "Karina Zevian",
        },
      ]
    },
    tags: ['animation', 'illustration', 'esg', 'environment'],
  },
  pasto: {
    title: "Do Prato ao Pasto",
    introduction: `Trase's plate-to-grass app helps consumers identify where the meat they buy in the market comes from and its environmental impact by scanning the bar code. We made this animation to promote the 1st version of the app.

    Another partnership with super director Márcio Isensee.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Production and finalization",
          name: '"Márcio Isensee',
        },
        {
          role: "Illustrations and animation",
          name: "Julia Lima",
        },
      ]
    },
    tags: ['animation', 'illustration', 'environment'],
  },
  direitos: {
    title: "Human Rights in Rural Work",
    introduction: `This booklet on human rights in rural work is the result of research by Ana Cristina Nobre from the Ambiente Social consultancy. This is the first of 6 themes, and serves as support material for facilitators to systematize complex themes. Visual support is very important in fieldwork and helps bring people closer to the subject.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and illustration",
          name: 'Carla Dutra',
        },
        {
          role: "Art direction",
          name: "Julia Lima",
        },
      ]
    },
    tags: ['publication', 'illustration', 'infographic', 'humanRights', 'work'],
  },
  rhino: {
    title: "Rhino Poops",
    introduction: `The Sumatran Rhinoceros is a closely monitored endangered species. But in order not to disturb the animals, scientists have developed an effective method of tracking families and preserving the species. Fun animation for Mongabay. It's great to be able to talk about positive things that are working.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Illustrations and animation",
          name: "Julia Lima",
        }, {
          role: "Script and voice over",
          name: "Manon Verchot",
        },
        {
          role: "Editorial",
          name: "Isabel Esterman, Basten Gokkon and Lucia Torres",
        },
      ]
    },
    tags: ['animation', 'illustration', 'environment'],
  },
  vozes: {
    title: "Vozes Femininas",
    introduction: `The book written by Tatiane Matheus had the voice of 21 women from different places and knowledge about diagnoses and solutions for the climate crisis. In the words of the author “It is a manifesto of the just transition in theory and, above all, in practice. A call for global thinking to local action. How ready are you to actually listen?” Working together with the <a class="text-link" href="https: //www.oc.eco.br/" target="__blank__">Observatório do Clima</a> Gender WG, it is clear that any possible recovery puts women at the center of the solution. Let's go together :)`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Graphic design and art direction",
          name: "Julia Lima",
        },
        {
          role: "Illustration and layout",
          name: "Ana Clara Lury",
        },
      ]
    },
    tags: ['women', 'publication', 'illustration', 'equality'],
  },
  olhar: {
    title: "Olhar Perto, Enxergar Longe",
    introduction: `The book "Look Close, See Far: Timeless Environmental Chronicles" is a collection produced by <a class="text-link" href="https://oeco.org.br/" target="__blank__">O Eco</a> and organized by journalist Lorenzo Aldé with the chronicles of journalist and photographer Marcos Sá Corrêa. The book brings together 98 columns on the most varied environmental topics and Marcos Sá's observations are surprisingly up-to-date, worth reading :)`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and graphic design",
          name: "Laura Levín e Julia Lima",
        },
        {
          role: "Cover",
          name: "Carla Dutra",
        },
      ]
    },
    tags: ['environment', 'publication'],
  },
  guia: {
    title: "Um Guia para os Perplexos",
    introduction: `Prepared by <a class="text-link" href="https://laclima.org/" target="__blank__">LACLIMA</a> and <a class="text-link" href="https: //www.oc.eco.br/" target="__blank__">Observatório do Clima</a> the guide for the perplexed explains everything you need to know about the Paris Agreement and the functioning of the UN climate COPs. Here we did the graphic design and layout, punctuating the text with some collages. Very rich and easy-to-read material for anyone who wants to understand how this negotiation is going, which in turn is not easy.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and graphic design",
          name: "Rachel Gepp e Julia Lima",
        },
      ]
    },
    tags: ['publication', 'illustration', 'environment'],
  },
  sitawi: {
    title: "Sitawi",
    introduction: `Sitawi is an organization that mobilizes resources for projects with a positive impact. That was a fun explainer video to make. Another happy partnership with <a class="text-link" href="http://cadenzafilmes.com.br/" target="_blank">Cadenza</a>.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Direction and production",
          name: "Cadenza Filmes",
        },
        {
          role: "Script",
          name: "Filipe Tomassini",
        },
        {
          role: "Layout and animation",
          name: "Julia Lima",
        },
      ]
    },
    tags: ['illustration', 'equality', 'animation', 'environment'],
  },
  mata: {
    title: "Mata Atlântica: Novas Histórias",
    introduction: `This <a class="text-link" href="https://oeco.org.br/especial/mataatlantica/" target="_blank">special about the Atlantic Forest</a> published in ((o)) eco , in partnership with the Serrapilheira Institute, featured 10 reports and 10 articles talking about actions to restore the Atlantic Forest, our most deforested biome.

    In addition to the visual identity, illustrations and infographics, the project was deployed in a <a class="text-link" href="https://oeco.org.br/biblioteca/mata-atlantica-novas-historias/" target="_blank">ebook</a> with all the reports, <a class="text-link" href="https://oeco.org.br/biblioteca/biodiversidade-em-pauta-um-guia-para-comunicadores-ebook/" target="_blank">a guide for communicators</a> with material on biodiversity of all biomes and even an online seminar on the subject.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Visual Identity, illustrations and graphic design",
          name: "Julia Lima",
        },
        {
          role: "Design assistant",
          name: "Carla Dutra",
        },
        {
          role: "Intern",
          name: "Ana Clara Lury",
        },
      ]
    },
    tags: ['animation', 'illustration', 'territory', 'environment', 'infographic'],
  },
  vozesin: {
    title: "Vozes Indígenas",
    introduction: `Animation project for the campaign of the Guardians of Iriri, from the Arara People. A partnership initiative of <a class="text-link" href="https://en.institutomaira.org/" target="_blank">Instituto Maíra</a> and <a class="text-link" href="https://anistia.org.br/" target="_blank">Anistia Internacional</a>, who support the historical resistance and struggle of the Arara to guarantee their territorial rights and the global urgency to preserve the environment environment.
    Our work involves a deep understanding of the socio-environmental struggle, which allows us to create a script and build images that touch reality, with sensitivity and respect for each theme.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Script, illustration and creative direction",
          name: "Rachel Gepp",
        },
        {
          role: "Voice over",
          name: "Carla Marques e Solaira Studios",
        },
        {
          role: "Animation",
          name: "Julia Lima",
        },
        {
          role: "Sound design",
          name: "Rodrigo Chuva",
        },
      ]
    },
    tags: ['animation', 'illustration', 'territory', 'environment', 'humanRights'],
  },
  plastico: {
    title: "Sem Plástico",
    introduction: `This explanatory video was made in 2019 in partnership with the production company Cadenza. Here we made the illustrations and animations of this WWF initiative with Banco do Brasil to reduce the consumption of plastics.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Production",
          name: "Cadenza",
        },
        {
          role: "Art direction",
          name: "Julia Lima",
        },
        {
          role: "Illustrations",
          name: "Rachel Gepp",
        },
        {
          role: "Animation",
          name: "Marcelo Antônio e Julia Lima",
        },
      ]
    },
    tags: ['animation', 'illustration', 'environment', 'esg'],
  },
  captura: {
    title: "Captura Corporativa",
    introduction: `In times of threat to our institutions, we take the opportunity to remember this animation made in partnership with <a class="text-link" href="https://www.escr-net.org/" target="_blank">ESCR -Net</a>. An initiative by the human rights community to draw attention to the practice of "corporate capture", this tendency of corporations to take over our hard-won democratic institutions.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Art direction and illustrations",
          name: "Rachel Gepp",
        },
        {
          role: "Animation",
          name: "Julia Lima",
        },
      ]
    },
    tags: ['illustration', 'animation', 'environment', 'humanRights', 'politics'],
  },
  despolarize: {
    title: "Despolarize",
    introduction: `This project was done at the height of polarization, right before the pandemic. We made this animation for the NGO <a class="text-link" href="https://www.politize.com.br/" target="_blank">Politize!</a> who works with political education in favor of democracy The video disseminates the material created by them to face this issue and create bridges of dialogue in a scenario of many rifts between families and friends.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Illustrations and animation",
          name: "Julia Lima",
        },
        {
          role: "Script, voice over and production",
          name: "Danila Bustamante e Felipe Maia",
        },
      ]
    },
    tags: ['illustration', 'animation', 'politics'],
  },
  alianza: {
    title: "Alianza",
    introduction: `After a year of activity, <a class="text-link" href="https://alianzafondosdelsur.org/" target="_blank">Alianza</a>, which brings together local funds from various countries in the Global South, has published its first report, launching its communication efforts! To enhance its communication strategy, we also created a website for the institution to post news, reports, and data, as well as an informative folder for events. We have added to Alianza’s identity the diversity of both the people and the biomes where they operate. <a class="text-link" href="https://alianzafondosdelsur.org/" target="_blank">www.alianzafondosdelsur.org</a>`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and graphic design",
          name: "Rachel Gepp and Julia Lima",
        },
        {
          role: "Web development",
          name: "Felipe Fernandes",
        },
        {
          role: "Design assistant",
          name: "Ana Clara Lury",
        },
      ]
    },
    tags: ['illustration', 'infographic', 'socialMedia', 'publication', 'territory', 'environment', 'humanRights', 'equality', 'web'],
  },
  clima: {
    title: "Em Nome do Clima - Mapeamento Crítico",
    introduction: `<p>The goal of this research was to understand and give visibility to the strategies adopted by governments and neo-extractive companies (hydro-agro-energy-mineral complex) in the face of the climate crisis and the implications of their use of the categories energy transition, renewable energy, decarbonization, and compensation.</p>
    <p>Utópika's role was to translate these complex data and obtuse discourses from governmental and business strategies into accessible and comprehensible design. We worked to make this information clear and impactful, facilitating the understanding and engagement of civil society.</p>
    <p>For the dissemination of the study, we produced a web series with five animations, reports, infographics, and cards for social media, making it easier to access this vital information so that more people can understand and act upon it.</p>`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Graphic design and illustrations",
          name: "Rachel Gepp e Julia Lima",
        },
        {
          role: "Design assistant",
          name: "Beatriz Fukumoto",
        },
      ]
    },
    tags: ['publication', 'illustration', 'infographic', 'territory', 'environment', 'humanRights'],
  },
  forest: {
    title: "Forest Ventures",
    introduction: `This podcast series looks at the forest from a business perspective and shows that the standing forest is at the core of a series of initiatives involving environmental and social commitment. To build this visual identity, we started with elements common to the world of investments, such as graphs, as well as from nature, like leaves, and the curves of rivers that also appear in the sound waves.`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Graphic design",
          name: "Julia Lima",
        },
        {
          role: "Design and Illustration Assistant",
          name: "Ana Clara Lury",
        },
      ]
    },
    tags: [en.SOCIAL_MEDIA, en.VISUAL_IDENTITY, en.ILLUSTRATION, en.ENVIRONMENT],
  },
  populacao: {
    title: "LGBTI+ Population Deprived of Liberty in Brazil",
    introduction: `We faced the challenge of bringing visibility to the conditions faced by LGBTI+ individuals in the Brazilian prison system, using art to sensitize how critical information is perceived and understood. Our biggest challenge was to illustrate this sensitive and complex topic in a way that respected the seriousness of the matter while simultaneously facilitating understanding. Thank you <a class="text-link" href="https://www.somos.org.br/" target="_blank">@somos</a>, <a class="text-link" href="https://www.apt.ch/pt" target="_blank">@apt</a> and <a class="text-link" href="https://mnpctbrasil.wordpress.com/" target="_blank">@mecanismo</a> for your trust!`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Graphic design and illustrations",
          name: "Rachel Gepp",
        },
      ]
    },
    tags: [en.PUBLICATION, en.ILLUSTRATION, en.EQUALITY, en.HUMAN_RIGHTS],
  },
  p4f: {
    title: "Partnerships for Forests",
    introduction: `<p>Partnerships for Forests is a British government program that boosts forest businesses and what is needed to make them viable. It's hard to summarize here four years of engagement and so many lessons learned; the truth is that we have seen the topic become more substantial over this time.</p>
    <p>From 2020 to 2024, there were over 30 case studies, portfolios, guides, animations, among many other deliveries for the Latin America branch. Many interesting projects have passed through there, and consequently, through here as well. We are just grateful for the internal and external team that made so many impressive things happen.</p>`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Coordination",
          name: "Julia Lima",
        },
        {
          role: "Animation and Graphic Projects",
          name: "Julia Lima",
        },
        {
          role: "Designers",
          name: "Carla Dutra, Laura Levín, Ana Clara Lury, Ana Clara Abbate e Beatriz Fukumoto",
        },
      ]
    },
    tags: [en.PUBLICATION, en.ANIMATION, en.ENVIRONMENT, en.INFOGRAPHIC, en.SOCIAL_MEDIA],
  },
  facanhas: {
    title: "Façanhas do Homem na Floresta",
    introduction: `<p>We had the pleasure of embracing ICMBio and doing the graphic design and layout of the book “Feats of Man in the Forest - memories of 30 years of biodiversity conservation”, which pays homage to the career of the civil servant Raimundo Façanha and his path to becoming IBAMA.</p> 
    <p>The history of biodiversity conservation over the last 30 years in the Carajás region is intertwined with Façanha's living testimony.</p> 
    <p>In a narrative filled with vibrant stories and memories, the book allows an analysis of the territorial dynamics and social transformations of the region.</p>`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and Graphic Design",
          name: "Rachel Gepp",
        },
        {
          role: "Design assistant",
          name: "Ana Clara Lury",
        },
      ]
    },
    tags: [en.ILLUSTRATION, en.PUBLICATION, en.ENVIRONMENT, en.INFOGRAPHIC, en.TERRITORY],
  },
  solidaria: {
    title: "Economia Solidária Digital",
    introduction: `<p>For us at Utópika, participating in this project was an opportunity to reaffirm our values: design as a powerful tool for social transformation. Our work goes beyond visual creation; we strive to facilitate the understanding of complex topics and generate engagement, connecting people with issues that are important for their future.</p>
    <p>It is an honor for us not only to take part in the creation of narratives that promote social justice in an honest and transparent way but also to be considered a voice in this process.</p>
    <p>Explore the book and get involved in shaping the future of work and this new economy!</p>
    <p>Thank you so much <a class="text-link" href="https://www.instagram.com/danielsantini/" target="_blank">@danielsantini</a> and <a class="text-link" href="https://www.instagram.com/grohmannrafael/" target="_blank">@grohmannrafael</a> and <a class="text-link" href="https://rosalux.org.br/" target="_blank">Rosa Luxemburg Foundation</a></p>
    `,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Graphic design and illustrations",
          name: "Rachel Gepp",
        },
        {
          role: "Design assistant",
          name: "Ana Clara Abbate",
        },
        {
          role: "Book launch video",
          name: "Diego da Silveira",
        },
      ]
    },
    tags: [en.PUBLICATION, en.EQUALITY, en.POLITICS, en.HUMAN_RIGHTS, en.WORK, en.SOCIAL_MEDIA],
  },
  agricultura: {
    title: "Agricultura Familiar e Clima",
    introduction: `<p>With great enthusiasm, we launch this campaign together with <a class="text-link" href="https://www.instagram.com/contag_brasil/" target="_blank">@contag_brasil</a> and <a class="text-link" href="https://www.instagram.com/observatoriodoclima/" target="_blank">@observatoriodoclima</a>, creating materials for training family farmers on climate issues. We learned a lot! Another crucial chapter in the conversation about climate justice, as family farming is essential for food security and will be the most affected by extreme weather events.</p>
    <p>This project was also very special because it allowed us to bring together both old and new partnerships. For the visual identity, we had the collaboration of <a class="text-link" href="https://www.instagram.com/lalaulevin/" target="_blank">@lalaulevin</a>, the podcast connected us with the amazing <a class="text-link" href="https://www.instagram.com/georgiapsantos/" target="_blank">@georgiapsantos</a> and <a class="text-link" href="https://www.instagram.com/todavos/" target="_blank">@todavos</a>, and for the website, our long-time partner <a class="text-link" href="https://www.instagram.com/felipedfe/" target="_blank">@felipedfe</a>. We also created a handbook, animations, and there's still more material to come!<p>
    `,
    credits: {
      title: "Credits",
      info: [
        {
          "role": "Graphic Design and Illustrations",
          "name": "Julia Lima and Rachel Gepp"
        },
        {
          "role": "Visual Identity",
          "name": "Julia Lima and Laura Levin"
        },
        {
          "role": "Animation",
          "name": "Julia Lima"
        },
        {
          "role": "Voice-over",
          "name": "Raul Zoche"
        },
        {
          "role": "Design Assistance",
          "name": "Beatrik Fukumoto"
        },
        {
          "role": "Podcast Editing",
          "name": "Todavos"
        }
      ]
    },
    tags: [en.ILLUSTRATION, en.WEB, en.ENVIRONMENT, en.PUBLICATION, en.ANIMATION, en.SOCIAL_MEDIA, en.VISUAL_IDENTITY, en.WORK],
  },
  "mare-de-ciencia": {
    title: "Maré de Ciência",
    introduction: `<p>We developed a special project for <a class="text-link" href="https://oeco.org.br/" target="_blank">O Eco</a> and <a class="text-link" href="https://maredeciencia.eco.br/" target="_blank">Maré de Ciência</a>: an educational booklet that connects schools to ocean culture, encouraging children to explore and understand the importance of the ocean for the planet’s future.</p>  
    <p>Working with environmental education is always a rewarding experience. In this project, we created visuals that not only communicate but also engage students, inspiring them to become agents of a more sustainable world. After all, the ocean belongs to all of us!</p>
    `,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and graphic design",
          name: "Rachel Gepp",
        },
      ]
    },
    tags: [en.PUBLICATION, en.ILLUSTRATION, en.ENVIRONMENT],
  },
  alziras: {
    title: "Alziras - Violência Política",
    introduction: `<p>During the electoral period, in partnership with <a class="text-link" href="https://alziras.org.br/" target="_blank">Instituto Alziras</a>, we developed a set of tools for monitoring and combating gender-based political violence. In this project, we produced a comprehensive publication, <a class="text-link" href="https://alziras.org.br/violenciapolitica/" target="_blank">an accessible website</a>, and informational materials for electoral advocacy, gathering data, response strategies, and support for victims.</p>
    <p>Our role was to transform this urgent issue into an accessible and informative design, creating visual resources that raise awareness and strengthen the fight against this form of violence, ensuring that more women can occupy political spaces without fear or silencing.</p>`,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and graphic design",
          name: "Rachel Gepp",
        },
        {
          role: "Design assistant",
          name: "Ana Clara Abbate",
        }
      ]
    },
    tags: [en.PUBLICATION, en.INFOGRAPHIC, en.WEB],
  },
  revolusolar: {
    title: "Revolusolar",
    introduction: `<p>Revolusolar is an organization committed to democratizing access to solar energy in low-income communities. Beyond installing solar panels, their work involves training residents, creating local job opportunities, and promoting environmental education. We had the pleasure of contributing to this project, making the content more accessible and inspiring through design.</p>
    <p>We wish success to our new friends at <a class="text-link" href="https://www.instagram.com/revolusolar/" target="_blank">@revolusolar</a> in this transformative initiative, which impacts lives and builds a more sustainable future.</p>
    `,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and graphic design",
          name: "Rachel Gepp",
        },
        {
          role: "Design assistant",
          name: "Ana Clara Abbate",
        }
      ]
    },
    tags: [en.PUBLICATION, en.ILLUSTRATION, en.EQUALITY],
  },
  mobilizacao: {
    title: "Mobilização dos Povos Pela Terra e Pelo Clima",
    introduction: `<p>The partnership between Utopika and Amana Comunicação is a union of purposes for social transformation. In alignment with COP30, we developed the visual identity for the mobilization promoted by the <a class="text-link" href="https://repam.org.br/" target="_blank">Pan-Amazonian Ecclesial Network (REPAM-Brasil)</a>, connecting the vibrant colors of the Amazon with the strength of youth and the diversity of its peoples.</p>
    <p>Our collaboration highlights how teams can, together, amplify the impact of each project, translating values into visual narratives that inspire and mobilize.</p>
    `,
    credits: {
      title: "Credits",
      info: [
        {
          role: "Layout and graphic design",
          name: "Rachel Gepp",
        },
      ]
    },
    tags: [en.SOCIAL_MEDIA, en.ILLUSTRATION, en.VISUAL_IDENTITY, en.TERRITORY, en.ENVIRONMENT],
  },
}

export default projectsPageEn;

{/* <a class="text-link" href="https://www.instagram.com/lalaulevin/" target="_blank">@lalaulevin</a>
<a class="text-link" href="https://www.instagram.com/georgiapsantos/" target="_blank">@georgiapsantos</a>
<a class="text-link" href="https://www.instagram.com/todavos/" target="_blank">@todavos</a>
<a class="text-link" href="https://www.instagram.com/felipedfe/" target="_blank">@felipedfe</a> */}